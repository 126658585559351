<template>
  <div class="header-page">
    <div class="header-box widthfix">
      <div class="box-logo" @click="$router.push('/')">
        <img src="@/assets/images/logo.png" alt="">
      </div>
      <!-- 移动端的横条 -->
      <div class="toggle_box" :class="{ active: istoggleshow }" @click="toggleslider">
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </div>
      <div class="box-nav">
        <router-link to="/" class="nav-item" :class="{ active: nowPath == '/' }">HOME</router-link>
        <router-link to="/about" class="nav-item" :class="{ active: nowPath == '/about' }">ABOUT US</router-link>
        <router-link to="/payments" class="nav-item" :class="{ active: nowPath == '/payments' }">LIVE
          PAYMENTS</router-link>
        <a class="nav-item" target="_black" href="https://favortask.com/#/register">SIGNUP</a>
        <a class="nav-item" target="_black" href="https://favortask.com/#/login">LOG IN</a>
      </div>
    </div>
    <!-- 移动端的样式 -->
    <div class="header_mobile" :class="{ isopen: istoggleshow }">
      <div class="mobile_toggle">
        <div class="toggle_box" :class="{ active: istoggleshow }" @click="toggleslider">
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </div>
      </div>
      <div class="mobile_nav">
        <ul>
          <li>
            <router-link to="/" @click.native="istoggleshow = false">HOME</router-link>
          </li>
          <li>
            <router-link to="/about" @click.native="istoggleshow = false">ABOUT US</router-link>
          </li>
          <li>
            <router-link to="/payments" @click.native="istoggleshow = false">LIVEPAYMENTS</router-link>
          </li>
          <li>
            <a target="_black" href="https://favortask.com/#/register">SIGNUP</a>
          </li>
          <li>
            <a target="_black" href="https://favortask.com/#/login">LOG IN</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'headerPage',
  data () {
    return {
      nowPath: '/',
      istoggleshow: false
    }
  },
  watch: {
    $route: {
      handler (to, from) {
        this.nowPath = to.path
      },
      immediate: true
    }
  },
  methods: {
    toggleslider () {
      this.istoggleshow = !this.istoggleshow
    }
  }
}
</script>

<style lang="less" scoped>
.header-page {
  height: 60px;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1;
  // background: linear-gradient(116deg, #EED6A3 0%, #DFBA7B 100%);

  .header-box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .box-logo {
      cursor: pointer;
      img {
        width: 154px;
      }
    }

    .toggle_box {
      display: none;
      cursor: pointer;

      &.active {
        .icon-bar:nth-child(1) {
          opacity: 0;
        }

        .icon-bar:nth-child(2) {
          transform: rotate(45deg) translate(1px, 1px);
        }

        .icon-bar:nth-child(3) {
          transform: rotate(-45deg) translate(.25rem, -0.25rem);
        }
      }

      .icon-bar {
        background: #222;
        display: block;
        width: 1.5625rem;
        height: .125rem;
        border-radius: 1px;
        margin-bottom: .3125rem;
        transition: all 0.3s;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .box-nav {
      display: flex;
      align-items: center;

      .nav-item {
        font-size: 16px;
        color: #4D4D4D;
        margin-right: 30px;
        position: relative;

        &.active {
          &::after {
            content: '';
            position: absolute;
            left: 50%;
            bottom: -10px;
            transform: translateX(-50%);
            border-radius: 26px;
            width: 24px;
            height: 3px;
            background-color: #305CFD;
          }
        }

        &:hover {
          color: #305CFD;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

  }

  .header_mobile {
    background: #f0f8f8;
    visibility: hidden;
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 4;
    transition: all 0.3s;

    &.isopen {
      opacity: 1;
      visibility: visible;
    }

    .mobile_toggle {
      display: flex;
      justify-content: center;
      padding: .9375rem 0;

      .toggle_box {
        cursor: pointer;

        &.active {
          .icon-bar:nth-child(1) {
            opacity: 0;
          }

          .icon-bar:nth-child(2) {
            transform: rotate(45deg) translate(1px, 1px);
          }

          .icon-bar:nth-child(3) {
            transform: rotate(-45deg) translate(.25rem, -.25rem);
          }
        }

        .icon-bar {
          background: #222;
          display: block;
          width: 1.5625rem;
          height: .125rem;
          border-radius: 1px;
          margin-bottom: .3125rem;
          transition: all 0.3s;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    .mobile_nav {
      margin-top: -2.875rem;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      ul {

        // display: flex;
        li {
          text-align: center;

          a {
            display: inline-block;
            padding: .875rem 1rem;
            color: #4D4D4D;
            font-size: .875rem;
            transition: color 0.3s ease;

            &:hover {
              color: #222;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    height: 3.125rem;

    .header-box {
      .box-logo {
        img {
          width: 6.125rem;
        }
      }

      .toggle_box {
        display: block;
      }

      .box-nav {
        display: none;
      }
    }
  }
}
</style>
