<template>
  <div class="footer-page">
    <div class="footer-box widthfix">
      <div class="footer-logo">
        <img src="@/assets/images/logo.png" alt="">
      </div>
      <div class="footer-right">
        <div class="footer-item">
          <div class="item-title">FavorTask</div>
          <div class="item-text-list">
            <router-link to="/about" class="text">About Us</router-link>
            <router-link to="/payments" class="text">Live Payments</router-link>
            <router-link to="" class="text">Contacts</router-link>
          </div>
        </div>
        <div class="footer-item">
          <div class="item-title">Information</div>
          <div class="item-text-list">
            <router-link to="" class="text">Terms</router-link>
            <router-link to="" class="text">Privacy Policy</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'footerPage'
}
</script>

<style lang="less" scoped>
.footer-page {
  background: linear-gradient(116deg, #19191A 0%, #0F0F0F 100%);
  padding: 100px 0;

  .footer-box {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .footer-logo {
      img {
        width: 154px;
      }
    }

    .footer-right {
      display: flex;

      .footer-item {
        margin-right: 60px;

        &:last-child {
          margin-right: 0;
        }

        .item-title {
          color: #fff;
          font-weight: 600;
          font-size: 29px;
        }

        .item-text-list {
          .text {
            color: #AAAAAA;
            font-size: 20px;
            display: block;
            margin-top: 20px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    padding: 3.125rem 0;
    .footer-box{
      display: block;
      .footer-logo{
        img{
          width: 6.25rem;
        }
      }
      .footer-right{
        margin-top: 5rem;
        .footer-item{
          margin-right: 3.75rem;
          .item-title{
            font-size: 1.125rem;
            margin-bottom: 3.125rem;
          }
          .item-text-list{
            .text{
              font-size: .75rem;
              margin-top: 1.25rem;
              &:nth-child(1){
                margin-top: 0;
              }
            }
          }
        }
      }
    }
  }
}
</style>
